<template>
	<div ref="input-code" class="input-code">
		<div class="inputs">
			<input id="input-code-0" @paste="eventPaste" :value="getValueCode(0)">
			<input id="input-code-1" @paste="eventPaste" :value="getValueCode(1)">
			<input id="input-code-2" @paste="eventPaste" :value="getValueCode(2)">
			<input id="input-code-3" @paste="eventPaste" :value="getValueCode(3)">
		</div>
		<p v-if="typeof message === 'string'">{{ message }}</p>
	</div>
</template>

<script>
export default {
	name: 'InputCodeComponentEBRADI',
	props: {
		value: {
			type: String,
			required: false,
			default: ''
		},
		message: {
			type: String,
			default: null,
			required: false
		}
	},
	data() {
		return {
			code: ''
		}
	},
	mounted() {
		this.$refs['input-code'].addEventListener('keypress', (event) => {
			event.preventDefault()

			let ketPress = event.key.replace(/\D/g, '');
			
			if (!isNaN(ketPress) && this.code.length < 4) {
				this.code += ketPress

				this.focus()
				
				return
			}
		})

		this.$refs['input-code'].addEventListener('keydown', (event) => {
			if (event.key && event.key === 'Backspace') {
				let result = this.code.split('')

				result.pop()

				this.code = result.join('')

				this.focus()
			}
		})
	},
	watch: {
		value(value) {
			if (typeof value === 'string') {
				value = value.replace(/\D/g, '')
				value = value.split('')
				value = value.slice(0, 4)

				this.code = value.join('')

				this.focus()
			}
		},
		code(value) {
			this.$emit('input', value)
		}
	},
	methods: {
		focus() {
			let number = this.code.length

			if (number > 4) {
				number = 4
			}

			let input = document.querySelector(`#input-code-${number}`)

			if (input) {
				input.focus()
			}
		},
		getValueCode(index) {
			if (this.code.length > 0 && typeof this.code === 'string') {
				if (this.code.length >= index) {
					return this.code[index]
				}
			}

			return ''
		},
		eventPaste(event) {
			event.preventDefault()

			let text = event.clipboardData.getData('text').replace(/\D/g, '')

			if (typeof text === 'string') {
				text = text.split('')
				text = text.slice(0, 4)

				this.code = text.join('')

				this.focus()
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.input-code {
	& > .inputs {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 16px;

		& > input {
			color: var(--medium-gray);
			font-size: 36px;
			text-align: center;
			height: 100px;
			width: 64px;
			background: transparent!important;
			border-radius: 12px;
			box-shadow: none;
			border-color: #BADFE7;
		}
	}
}
</style>
