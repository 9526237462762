<template>
	<section class="container">
		<Card>
			<div>
				<h2 class="has-text-centered is-size-3 pt-4">
					Cursos adquiridos
				</h2>
				<h5 
					class="has-text-centered my-5"
				>
					{{ subTitle }}
				</h5>
				<form class="form-cpf">
					<div v-if="!getCodigo">
						<b-field
							v-mask="['###.###.###-##']"
							label="CPF"
							class="m-auto"
						>
							<b-input
								v-model="form.cpf"
								class="input-custom"
							/>
						</b-field>
					</div>
					<div v-if="getCodigo">
						<InputCode v-model="form.code" />
					</div>
					<div class="btns">
						<b-button
							type="is-secondary"
							rounded
							@click="submit"
						>
							Continuar
						</b-button>
					</div>
					<div v-if="getCodigo" class="btns" style="margin-top: 0px;">
						<b-button
							type="is-secondary"
							rounded
							outlined
						>
							Reenviar código
						</b-button>
					</div>
					<div  v-if="getCodigo" class="mt-4">
						<p>Não possui mais acesso ao email? <a href="#">Fale conosco</a></p>
					</div>
				</form>
			</div>
		</Card>
	</section>
</template>

<script>
import Card from '@ebradi/componets/Card'
import InputCode from '@ebradi/componets/InputCode'

export default {
	name: "PagaLoginEBRADI",
	components: {
		Card,
		InputCode
	},
	data() {
		return {
			getCodigo: false,
			subTitle: 'Insira seu CPF e você receberá um código de acesso via email',
			form: {
				cpf: null,
				code: ''
			}
		}
	},
	methods: {
		submit() {
			this.getCodigo = !this.getCodigo

			this.subTitle = (this.getCodigo)
				? 'Insira o código de 4 digitos que você recebeu no email'
				: 'Insira seu CPF e você receberá um código de acesso via email' 
		}
	}
}
</script>

<style lang="scss" scoped>
.container {
	width: 704px;
}

h2 {
	line-height: 40px;
	font-size: 32px;
	font-weight: 700;
}

h5 {
	line-height: 24px;
	font-size: 16px;
	font-weight: 400;
}

.input-custom {
	&::v-deep .input {
		background: transparent;
	}
}

.text-active {
	color: var(--turquoise-blue);
}

.form-cpf {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	& > div:first-child {
		width: 280px;
		padding: 42px 0;
	}
}

.btns {
	margin-top: 42px;
	margin-bottom: 16px;

	::v-deep {
		.button {
			width: 220px;
			height: 52px;
		}
	}

}

</style>
